<template>
	<v-card class="flex d-flex flex-column" :color="background ? 'primary' : 'black'" @click="dialog = true">
		<div class="text-center justify-center" style="height: 100%">
			<v-card :color="background ? 'primary' : 'black'" flat tile>
				<v-row style="padding: 0; margin: 0; height: 100%">
					<v-col md="2" />

					<v-col md="8">
						<div style="width: 100%; display: flex">
							<v-img alt="On.Energy Project" class="mb-3" contain height="360"
								:src="require(`@/assets/${picture}`)" width="100%" />
						</div>

						<div class="text-h5 font-weight-semibold" :class="background ? 'black--text' : 'primary--text'">
							{{ teamMember }}
						</div>

						<div class="text-subtitle-1" :class="background ? 'black--text' : 'white--text'"
							style="padding-bottom: 30px">
							{{ position }}
						</div>
					</v-col>

					<v-col md="2" />
				</v-row>
			</v-card>
		</div>

		<v-dialog v-model="dialog" width="80%">
			<v-card style="background-color: #000; min-height: 600px" :color="background ? 'primary' : 'black'">
				<v-card-title style="justify-content: flex-end; margin-bottom: 30px">
					<v-btn :color="background ? 'black' : 'primary'" text @click="dialog = false"
						class="text-h4 font-weight-semibold">
						X
					</v-btn>
				</v-card-title>

				<v-row no-gutters>
					<v-col md="1" />

					<v-col md="3" style="display: flex; align-items: center">
						<v-row style="padding: 0" class="flex-column">
							<div style="display: flex; align-items: center;padding-bottom: 30px">
								<v-img alt="On.Energy Project" height="auto" :src="require(`@/assets/${picture}`)"
									width="300px" />
							</div>

							<div class="text-h4 font-weight-semibold text-center"
								v-bind:class="(background) ? 'black--text' : 'primary--text'">
								{{ teamMember }}
							</div>

							<div class="text-subtitle-1 text-center"
								v-bind:class="(background) ? 'black--text' : 'white--text'">
								{{ position }}
							</div>
						</v-row>
					</v-col>

					<v-col md="1"></v-col>

					<v-col cols="11" md="6" style="color: #fff">
						<div>
							<div style="align-items: center; font-size: 80%; font-weight: 100 ;padding: 20px 0px 0px 30px"
								v-bind:class="(background) ? 'black--text' : 'white--text'">
								<div v-html="resume" />
							</div>

							<div class="font-weight-light text-h6" style="
									align-items: center;
									padding: 30px 0 50px 20px;
									display: flex;
									width: 200px;
								">
								<v-btn icon :href="linkedin" target="_blank" style="width: 200px">
									<v-img alt="Linkedin" contain src="@/assets/linkedin_icon--dark.svg"
										transition="fade-transition" width="50px" style="padding-right: 20px"
										v-if="background" />

									<v-img alt="Linkedin" contain src="@/assets/linkedin_icon.svg"
										transition="fade-transition" width="50px" style="padding-right: 20px"
										v-if="!background" />


									<span class="font-weight-light text-h6"
										v-bind:class="(background) ? 'black--text' : 'white--text'">
										Linkedin Profile
									</span>
								</v-btn>
							</div>
						</div>
					</v-col>

					<v-col md="1" />
				</v-row>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
export default {
	name: 'CardTeam',
	data() {
		return {
			dialog: false,
		};
	},
	props: {
		teamMember: {
			type: String,
		},
		picture: {
			type: String,
		},
		position: {
			type: String,
		},
		background: {
			type: Boolean,
		},
		resume: {
			type: String,
		},
		linkedin: {
			type: String,
		},
	},
};
</script>
