<template>
	<v-footer class="d-flex flex-column px-0 py-16" color="#eaeaea">
		<v-container>
			<Offices />

			<Links />
		</v-container>
	</v-footer>
</template>

<script>
import Offices from './footer/Offices.vue';
import Links from './footer/Links.vue';

export default {
	name: 'Footer',
	components: { Offices, Links },
	data: () => ({
		//
	}),
};
</script>
