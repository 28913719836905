<template>
	<v-card
		:img="require(`@/assets/${image}`)"
		class="section__image d-flex align-center justify-center text-center"
		color="black"
		dark
		flat
		height="700"
		tile
	>
		<v-container style="max-width: 800px">
			<v-row>
				<v-col class="d-flex flex-column align-center justify-center" cols="12">
					<div
						class="mb-6 pt-16 pt-md-0 text-h3 text-md-h2 font-weight-bold primary--text notranslate"
					>
						{{ title }}
					</div>

					<div class="text-h5 font-weight-semibold">
						{{ text }}
					</div>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
export default {
	name: 'SectionHead',
	data: () => ({
		//
	}),
	props: ['title', 'text', 'image'],
};
</script>
