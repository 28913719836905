<script>
export default {
    name: "PageNotFound",
    data: () => ({
        //
    }),
    created: function () {
        // Redirect outside the app using plain old javascript
        window.location.href = "/";
    },
};
</script>
