<template>
	<v-row>
		<v-col cols="12" md="6">
			<v-img alt="On.Energy" class="mx-auto mr-md-0 ml-md-auto pt-16 mt-8 pt-md-0 mt-md-0" contain
				src="@/assets/element__welcome.png" height="100%" max-width="800" transition="fade-transition"
				width="100%" />
		</v-col>

		<v-col cols="12" md="6">
			<v-card color="black" dark flat tile height="100%">
				<v-container class="ml-xl-0 py-16" style="max-width: 600px">
					<v-row>
						<v-col class="d-flex flex-column" cols="12">
							<div class="pb-3 text-h2 font-weight-bold">
								welcome to the
								<span class="primary--text">future of energy</span>
							</div>

							<div class="pt-3 pb-12 text-h5">
								we are a fully-integrated energy storage developer, technology
								company and asset manager. using proprietary software,<br />
								<span class="primary--text">
									we deliver end-to-end projects with available in-house
									financing
								</span>
							</div>

							<div>
								<v-btn to="/contact" class="font-weight-bold black--text px-16 py-8 rounded-0"
									color="primary" elevation="0" x-large>
									Let's Connect
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Welcome',
	data: () => ({
		//
	}),
};
</script>
