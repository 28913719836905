<template>
	<div>
		<carousel-3d @after-slide-change="onAfterSlideChange" border="0" class="d-sm-none" clickable height="200"
			ref="mycarouselmobile" width="233">
			<slide :index="0">
				<v-img alt="On.Command™" contain src="@/assets/software/client_consumption.png" />
			</slide>

			<slide :index="1">
				<v-img alt="On.Command™" contain src="@/assets/software/client_consumption.png" />
			</slide>

			<slide :index="2">
				<v-img alt="On.Command™" contain src="@/assets/software/client_consumption.png" />
			</slide>
		</carousel-3d>

		<carousel-3d @after-slide-change="onAfterSlideChange" border="0" class="d-none d-sm-block" clickable height="500"
			ref="mycarousel" width="614">
			<slide :index="0" class="pt-3">
				<v-img alt="On.Command™" contain src="@/assets/software/client_savings.png" />
			</slide>

			<slide :index="1" class="pt-3">
				<v-img alt="On.Command™" contain src="@/assets/software/client_power.png" />
			</slide>

			<slide :index="2" class="pt-3">
				<v-img alt="On.Command™" contain src="@/assets/software/client_consumption.png" />
			</slide>

			<slide :index="3" class="pt-3">
				<v-img alt="On.Command™" contain src="@/assets/software/client_environmental.png" />
			</slide>
		</carousel-3d>

		<v-container>
			<v-row>
				<v-col class="d-flex align-center justify-space-between" cols="12">
					<v-btn @click="goPrevMobile()" aria-label="Go to previous image" class="d-sm-none" color="white" icon
						x-large>
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>

					<v-btn @click="goPrev()" aria-label="Go to previous image" class="d-none d-sm-block" color="white" icon
						x-large>
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>

					<div class="primary--text px-10 text-h5 text-md-h4 text-center">
						{{ title }}
					</div>

					<v-btn @click="goNextMobile()" aria-label="Go to next image" class="d-sm-none" color="white" icon
						x-large>
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>

					<v-btn @click="goNext()" aria-label="Go to next image" class="d-none d-sm-block" color="white" icon
						x-large>
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
.carousel-3d-slide {
	background-color: transparent;
}

.carousel-3d-slide.current {
	&::after {
		background: url('@/assets/features__border.svg') no-repeat !important;
		content: '';
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
</style>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
	name: 'SoftwareGallery',
	components: {
		Carousel3d,
		Slide,
	},
	data: () => ({
		title: 'savings dashboard',
		titles: [
			'savings dashboard',
			'power quality dashboard',
			'consumption dashboard',
			'emission dashboard',
		],
	}),
	methods: {
		onAfterSlideChange(index) {
			this.title = this.titles[index];
		},
		goNextMobile() {
			this.$refs.mycarouselmobile.goNext();
		},
		goPrevMobile() {
			this.$refs.mycarouselmobile.goPrev();
		},
		goNext() {
			this.$refs.mycarousel.goNext();
		},
		goPrev() {
			this.$refs.mycarousel.goPrev();
		},
	},
};
</script>
