<template>
	<div style="position: relative">
		<v-container style="padding-bottom: 200px; position: relative; z-index: 2">
			<v-row>
				<v-col class="d-flex align-center flex-column mb-12 text-center" cols="12">
					<v-img alt="On.Command™" class="mb-12" contain height="292" max-width="100%"
						src="@/assets/on-command-white_1.png" transition="fade-transition" width="400" />

					<div class="mt-16 mb-9 text-h3 font-weight-bold">
						all-new interface
					</div>

					<v-img alt="On.Command™ Energy Activity" class="d-none d-md-block mb-6" contain height="188"
						src="@/assets/v2__energy-activity.png" transition="fade-transition" width="100%" />

					<v-img alt="On.Command™ Energy Activity" class="d-md-none mt-6 mb-16" contain height="330"
						max-width="300" src="@/assets/v2__energy-activity--mobile.png" transition="fade-transition"
						width="100%" />

					<div class="text-h3 font-weight-bold">easy. powerful. flexible</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	name: 'Interface',
	data: () => ({
		//
	}),
};
</script>
