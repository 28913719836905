<template>
	<v-card color="primary" flat tile height="100%">
		<v-container class="containerFeature" style="max-width: 600px;">
			<v-divider class="col-12 pa-0" />

			<div class="text-h4 text-md-h4 font-weight-bold black--text tittleFeature">
				{{ title }}
			</div>

			<div class="mt-auto tittleFeature" @click="dialog = true">
				<v-btn class="font-weight-bold rounded-0 pl-10 pr-10" color="black" dark elevation="0" target="_blank"
					large>
					more info
				</v-btn>
			</div>

			<v-dialog v-model="dialog" width="80%">
				<v-card style="background-color: #000; min-height: 600px; padding: 20px;">
					<v-card-title style="justify-content: flex-end; margin-bottom: 30px;">
						<v-btn size="small" density="compact" style="background-color: #FBED1D;" text
							@click="dialog = false" class="text-h4 font-weight-semibold rounded-0 pb-7 pt-7">
							X
						</v-btn>
					</v-card-title>

					<v-row>
						<v-col md="2" />

						<v-col md="8">
							<div class="text-h3 font-weight-semibold white--text mb-5">
								{{ title }}
							</div>

							<div class="text-h5 font-weight-semibold white--text mb-5">
								<span class="text-h5 font-weight-semibold">location: </span>
								<span class=" text-subtitle-1">{{ location }}</span>
							</div>

							<div class="text-h5 font-weight-semibold white--text mb-5">
								<span class="text-h5 font-weight-semibold ">system overview: </span><br />
								<ul class="text-subtitle-1 dash">
									<li>battery energy storage system</li>
									<li>20 MWh</li>
									<li>owned and operated by On.Energy ensuring
										a
										seamless and sustainable
										contribution to the region's energy landscape</li>
								</ul>
							</div>

							<div class="text-h5 font-weight-semibold white--text mb-5">
								<span class="text-h5 font-weight-semibold">project highlights: </span><br />
								<p class="text-subtitle-1 p-0" style="line-height: 150%;">{{
									highlights.toLocaleLowerCase()
								}}</p>
							</div>
						</v-col>

						<v-col md="2" />
					</v-row>
				</v-card>
			</v-dialog>
		</v-container>
	</v-card>
</template>

<style lang="scss">
.containerFeature {
	@media screen and (max-width: 600px) {
		padding-bottom: 40px !important;
	}

	@media screen and (min-width: 600px) {
		padding-top: 120px;
		padding-bottom: 120px
	}
}

.tittleFeature {
	@media screen and (max-width: 600px) {
		padding-top: 40px;
		text-align: center;
	}

	@media screen and (min-width: 600px) {
		padding-top: 10px;
		padding-bottom: 30px;
	}
}

.btn-porfolio {
	border: 2px solid #000;
	padding: 5px 20px;
	border-radius: 20px;
	font-weight: 900;
}

ul.dash {
	list-style: none;
	margin-left: 0;
	padding-left: 1em;
	line-height: 1.3em !important;
}

ul.dash>li:before {
	display: inline-block;
	content: "-";
	width: 1em;
	margin-left: -1em;
}
</style>


<script>
export default {
	name: 'CardFeatured',
	data() {
		return {
			dialog: false,
		};
	},
	props: {
		index: {
			type: String,
		},
		title: {
			type: String,
		},
		location: {
			type: String,
		},
		highlights: {
			type: String,
		},
		front: {
			type: Boolean,
		},
	}
};
</script>
