<template>
	<div>
		<div>
			<SectionHead title="our team" text="Behind On" image="head__team.jpg" />
		</div>

		<v-row style="padding: 0; margin: 0; background-color: #000;">
			<v-col v-for="(item, i) in items" :key="i" md="4" sm="6" xs="12" class="d-flex flex-column"
				style="padding: 0; margin: 0;">
				<CardTeam :teamMember=item.name.toLocaleLowerCase() :picture=item.picture :position=item.position
					:background=item.background :resume=item.resume.toLocaleLowerCase() :linkedin=item.linkedin />

			</v-col>
		</v-row>
		<div>
			<v-card color="black" flat tile style="overflow: hidden; padding-top: 160px; padding-bottom: 80px">
				<ContactUs />
			</v-card>
		</div>
	</div>
</template>

<script>
import SectionHead from '@/components/SectionHead.vue';
import CardTeam from '@/components/team/CardTeam.vue';
import ContactUs from '@/components/ContactUs.vue';
import infoTeam from '@/api/infoTeam.json';

var height = 200;

export default {
	name: 'Team',
	components: { SectionHead, CardTeam, ContactUs },
	data() {
		return {
			items: infoTeam,
		};
	},
};

</script>
