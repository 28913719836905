<template>
	<div>
		<v-card color="black" flat tile style="padding-top: 120px">
			<div class="text-h1">Single Project</div>
		</v-card>

		<v-container style="padding-top: 200px; padding-bottom: 200px">
			<LatestProjects />
		</v-container>

		<v-card color="black" flat tile>
			<ContactUs />
		</v-card>
	</div>
</template>

<script>
import LatestProjects from '@/components/LatestProjects.vue';
import ContactUs from '@/components/ContactUs.vue';

export default {
	name: 'Single Project',
	components: {
		LatestProjects,
		ContactUs,
	},
	data: () => ({
		//
	}),
};
</script>
