<template>
	<v-card color="black" dark flat tile style="padding: 160px 0">
		<v-container style="position: relative; z-index: 2">
			<v-row>
				<v-col class="mb-12 text-center" cols="12">
					<div class="mx-auto text-h3 font-weight-semibold mb-10 text-center onCommand" style="max-width: 600px">
						On.Command™
					</div>
					<div class="mx-auto text-h5 font-weight-semibold mb-8 text-center" style="max-width: 600px">
						we use proprietary technology to<br />
						<span class="primary--text">develop, design, forecast, control and dispatch</span><br />
						our assets - squeezing the most out of every kilowatt deployed
					</div>

					<div class="pb-9 text-h6 font-weight-semibold">
						On.Command™ interface is optimized for superior user experience
						and management
					</div>
				</v-col>
			</v-row>
		</v-container>

		<SoftwareGallery />

		<v-container class="mt-16" style="position: relative; z-index: 2">
			<v-row>
				<v-col class="d-flex justify-center" cols="12">
					<v-btn to="/solutions/software" class="font-weight-bold black--text px-16 py-8 rounded-0"
						color="primary" elevation="0" x-large>
						our software
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<style lang="scss" scoped>
.onCommand {
	@media screen and (max-width: 600px) {
		font-size: 2em !important;
	}
}
</style>

<script>
import SoftwareGallery from '@/components/solutions/software/SoftwareGallery.vue';

export default {
	name: 'Software',
	components: {
		SoftwareGallery,
	},
	data: () => ({
		//
	}),
};
</script>
