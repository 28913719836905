<template>
	<v-row>
		<v-col cols="12" md="6">
			<v-img alt="On.Energy" class="mx-auto mr-md-0 ml-md-auto pt-16 mt-8 pt-md-0 mt-md-0" contain
				src="@/assets/head__contact.png" height="100%" max-width="100%" transition="fade-transition" width="800" />
		</v-col>

		<v-col cols="12" md="6">
			<v-card color="black" flat tile height="100%">
				<v-container class="d-flex flex-column ml-xl-0 pt-16" style="max-width: 600px">
					<div class="mb-12 text-h3 font-weight-bold white--text text-alt text--underline">
						contact us
					</div>

					<v-form style="position: relative; z-index: 2">
						<v-col class="pt-0 px-0" cols="12">
							<v-text-field v-model.trim="name" background-color="white" class="rounded-0" color="black"
								filled flat hide-details id="name" label="Name" name="name" placeholder="Name" required />
						</v-col>

						<v-col class="pt-0 px-0" cols="12">
							<v-text-field v-model.trim="email" :error-messages="errorMessages" background-color="white"
								class="rounded-0" color="black" filled flat hide-details id="email" label="E-mail"
								name="email" placeholder="E-mail" required type="email" />
						</v-col>

						<v-col class="pt-0 px-0" cols="12">
							<v-text-field v-model.trim="company" background-color="white" class="rounded-0" color="black"
								filled flat hide-details id="company" label="Company" name="company" placeholder="Company"
								required />
						</v-col>

						<v-col class="pt-0 px-0" cols="12">
							<v-combobox v-model="country" :items="countryItems" background-color="white" class="rounded-0"
								color="black" filled flat hide-details id="country" label="Country/Region" name="country"
								placeholder="Fill in location or select from the list" required />
						</v-col>

						<v-col class="pt-0 px-0" cols="12">
							<v-select v-model="topic" :items="topics" background-color="white" class="rounded-0"
								color="black" filled flat hide-details id="topic" label="Topic" name="topic"
								placeholder="Topic" required />
						</v-col>

						<v-col class="pt-0 px-0" cols="12">
							<v-textarea v-model.trim="message" background-color="white" class="rounded-0" color="black"
								filled flat hide-details id="message" label="Message" name="message" no-resize
								placeholder="Message" required />
						</v-col>

						<v-col class="pt-0 px-0" cols="12">
							<v-btn @click="waitToSubmitForm" :disabled="isLoading"
								class="font-weight-bold black--text px-16 py-8 rounded-0" color="primary" elevation="0"
								x-large>
								send
							</v-btn>
						</v-col>

						<v-col class="pt-0 px-0" cols="12">
							<div id="google-recaptcha" />
						</v-col>

						<v-col class="pa-0" cols="12" style="margin-bottom: 100px; position: relative; width: 100%">
							<v-alert v-if="showSuccessMessage" class="rounded-0" dense prominent
								transition="fade-transition" type="success" style="position: absolute; width: 100%">
								Thank you for reaching out! We will attend to your request
								shortly.
							</v-alert>

							<v-alert v-if="showErrorMessage" class="rounded-0" dense prominent transition="fade-transition"
								type="error" style="position: absolute; width: 100%">
								There was an error while trying to send the message. Please
								check that all fields are filled correctly or refresh the page
								and try again.
							</v-alert>

							<v-alert v-if="showValidatorMessage" class="rounded-0" dense prominent
								transition="fade-transition" type="error" style="position: absolute; width: 100%">
								{{ validatorMessage }}
							</v-alert>
						</v-col>
					</v-form>
				</v-container>
			</v-card>
		</v-col>
	</v-row>
</template>

<style lang="scss">
.v-footer .text-decoration-underline {
	&:hover {
		color: var(--v-primary-base) !important;
	}
}

.v-text-field .v-input__control .v-input__slot:before {
	border-color: transparent !important;
	border-style: none !important;
	border-width: 0 !important;
}

.v-text-field>.v-input__control>.v-input__slot:before,
.v-text-field>.v-input__control>.v-input__slot:after {
	display: none !important;
}
</style>

<script>
import sendMessageApi from '../api/SendMessageApi';

export default {
	name: 'Contact',
	data: () => ({
		topics: [
			'General Inquiries',
			'Press',
			'Sales',
			'Investors',
			'Job Opportunities',
		],
		countriesRegions: [
			{
				country: 'Chile',
				regions: [],
			},
			{
				country: 'Colombia',
				regions: [],
			},
			{
				country: 'Costa Rica',
				regions: [],
			},
			{
				country: 'México',
				regions: [],
			},
			{
				country: 'Perú',
				regions: [],
			},
			{
				country: 'USA',
				regions: [
					'California',
					'Florida',
					'Massachusetts',
					'New York',
					'Puerto Rico',
					'Texas',
				],
			},
		],
		name: '',
		email: '',
		company: '',
		country: '',
		topic: '',
		message: '',
		validatorMessage: '',
		errorMessages: '',
		isLoading: false,
		showValidatorMessage: false,
		showSuccessMessage: false,
		showErrorMessage: false,
	}),
	computed: {
		countryItems() {
			let result = [];
			for (const { country, regions } of this.countriesRegions) {
				if (regions.length) {
					for (const region of regions) {
						result.push(`${country} / ${region}`);
					}
				} else {
					result.push(country);
				}
			}
			return result;
		},
	},
	methods: {
		validateForm() {
			const VALID_EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
			const LINK_DETECTED_REGEX_ADVANCED =
				/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
			const LINK_DETECTED_REGEX_SIMPLE =
				/([A-Za-z0-9\-\.]+\.(com|es|net|org|info|biz))/;
			const MAX_MESSAGE_LENGTH = 256;

			// verify null vales
			if (
				!this.name ||
				!this.email ||
				!this.company ||
				!this.country ||
				!this.topic ||
				!this.message
			) {
				this.validatorMessage =
					'There are empty fields. Please complete before sending a message.';
				return false;
			}
			// verify message lenght
			if (this.message.length > MAX_MESSAGE_LENGTH) {
				this.validatorMessage = `The maximum message length is ${MAX_MESSAGE_LENGTH} characters`;
				return false;
			}
			// verify email
			// @see	{@link https://tylermcginnis.com/validate-email-address-javascript/}
			// @see (@link https://www.w3resource.com/javascript/form/email-validation.php}
			if (!VALID_EMAIL_REGEX.test(this.email)) {
				this.validatorMessage =
					'The email format is invalid. Please double check before sending.';
				return false;
			}
			// verify links
			if (
				LINK_DETECTED_REGEX_ADVANCED.test(this.message) ||
				LINK_DETECTED_REGEX_SIMPLE.test(this.message)
			) {
				this.validatorMessage = "Sorry, the message can't have links";
				return false;
			}
			if (typeof grecaptcha === 'undefined' || null === grecaptchaId) {
				this.validatorMessage =
					"reCAPTCHA isn't loaded. Please reload the page and try again.";
				return false;
			}

			return true;
		},
		waitToSubmitForm() {
			/**
			 * Se coloca un timeout para asegurarme que el combobox haya actualizado el valor de "country".
			 * De lo contrario si se acciona el botón de submit mientras se está editando este, no se actualiza
			 * el valor para cuando se procesa la función de "submitForm".
			 */
			let that = this;
			window.setTimeout(function () {
				that.submitForm();
			}, 0);
		},
		submitForm() {
			this.showSuccessMessage = false;
			this.showErrorMessage = false;
			this.showValidatorMessage = false;

			if (!this.validateForm()) {
				this.showValidatorMessage = true;
				return;
			}

			this.isLoading = true;
			let that = this;
			grecaptcha.ready(function () {
				grecaptcha
					.execute(grecaptchaId, {
						action: 'submit',
					})
					.then((token) => {
						if (typeof token !== 'string' || token.length === 0) {
							that.isLoading = false;
							that.showErrorMessage = true;
						} else {
							that.sendMessage(token);
						}
					});
			});
		},
		sendMessage(token) {
			let sendMessageResp = sendMessageApi({
				name: this.name,
				email: this.email,
				company: this.company,
				country: this.country,
				topic: this.topic,
				message: this.message,
				reCaptchaToken: token,
				originDomine: process.env.VUE_APP_ORIGIN_DOMINE,
			});
			sendMessageResp
				.catch((err) => {
					this.showErrorMessage = true;
					this.isLoading = false;
				})
				.then((response) => {
					if (!response?.ok) return null;
					return response.json();
				})
				.then((res) => {
					if (!res || res.isError) {
						this.showErrorMessage = true;
					} else {
						this.showSuccessMessage = true;
						this.name = '';
						this.email = '';
						this.company = '';
						this.country = '';
						this.topic = '';
						this.message = '';
					}
					if (res?.debugging) {
						console.log(res);
					}
					this.isLoading = false;
				});
		},
	},
	mounted() {
		if (typeof grecaptcha === 'undefined') {
			// Cargo el script del Google ReCAPTCHA
			let newScript = document.createElement('script');
			newScript.setAttribute(
				'src',
				'https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoadCallback'
			);
			document.head.appendChild(newScript);
		} else {
			grecaptchaId = null;
			onRecaptchaLoadCallback();
		}
	},
};
</script>
