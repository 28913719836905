<template>
	<v-container>
		<v-row style="position: relative; z-index: 2">
			<v-col cols="12">
				<div class="text-h4 font-weight-semibold text-center mb-14">
					trusted by industry leaders
				</div>
			</v-col>

			<v-col v-for="(item, i) in items" :key="i" class="d-flex align-center" cols="6" sm="4" md="3">
				<div class="mx-auto" style="max-width: 100%">
					<v-img :height="item.height" :max-height="item.height" :src="item.logo" alt="Industry Leaders" contain
						max-width="180" transition="fade-transition" width="100%" />
				</div>
			</v-col>

			<v-col class="mt-14" cols="12">
				<div class="text-h5 font-weight-semibold text-center">
					and many more...
				</div>
			</v-col>

			<v-col class="d-flex justify-center mt-16" cols="12">
				<v-img alt="On.Energy" contain src="@/assets/element__dot-icon.png" height="220" max-width="400"
					transition="fade-transition" width="100%" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'Clients',
	data: () => ({
		items: [
			{
				logo: require('@/assets/logo__client--walmart.png'),
				height: '40',
			},
			{
				logo: require('@/assets/logo__client--bimbo.png'),
				height: '60',
			},
			{
				logo: require('@/assets/logo__client--glencore.png'),
				height: '40',
			},
			{
				logo: require('@/assets/logo__client--enelx.png'),
				height: '30',
			},
			{
				logo: require('@/assets/logo__client--parque-arauco.png'),
				height: '40',
			},
			{
				logo: require('@/assets/logo__client--skysense.png'),
				height: '60',
			},
			{
				logo: require('@/assets/logo__client--amresorts.png'),
				height: '50',
			},
			{
				logo: require('@/assets/logo__client--inkia.png'),
				height: '80',
			},
			{
				logo: require('@/assets/logo__client--sigma.png'),
				height: '60',
			},
			{
				logo: require('@/assets/logo__client--axiom.png'),
				height: '50',
			},
			{
				logo: require('@/assets/logo__client--quimp.png'),
				height: '80',
			},
			{
				logo: require('@/assets/logo__client--vol.png'),
				height: '70',
			},
		],
	}),
};
</script>
