<template>
	<v-row class="py-16">
		<v-col cols="12">
			<v-col cols="12">
				<v-row style="position: relative">
					<v-col class="d-flex flex-column flex-wrap" cols="12">
						<div class="text-h3 font-weight-bold text--underline">
							latest projects
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-row style="position: relative">
				<v-col cols="12" md="4">
					<Card class="px-3" title="cancun intl. airport" image="projects/1_cancun.jpg" application="12MW - 24MWh"
						details="load shifting & reactive power compensation" location="cancun, mexico" />
				</v-col>
				<v-col cols="12" md="4">
					<Card class="px-3" title="polaris" image="projects/28_polaris.jpg" application="630kW - 630 kWh"
						details="load shifting & reactive power compensation" :front=true location="peru" />
				</v-col>
				<v-col cols="12" md="4">

					<Card class="px-3" title="walmart retail center" image="projects/3_wallmart.jpg" application=""
						details="load shifting & reactive power compensation" location="mexico city, mexico" />
				</v-col>
			</v-row>
		</v-col>

		<v-col class="d-flex justify-center" cols="12">
			<v-btn to="/projects" class="font-weight-bold black--text px-16 py-8 rounded-0" color="primary" elevation="0"
				x-large>
				more projects
			</v-btn>
		</v-col>
	</v-row>
</template>

<style lang="scss" scoped>
.carousel-3d-slide {
	background-color: white;
	opacity: 0 !important;
	z-index: 970 !important;

	&.current {
		opacity: 1 !important;
		z-index: 980 !important;
	}

	&.right-1 {
		opacity: 1 !important;
		z-index: 980 !important;
	}

	&.left-1 {
		opacity: 1 !important;
		z-index: 980 !important;
	}
}
</style>

<script>
import Card from '@/components/projects/Card.vue';

export default {
	name: 'LatestProjects',
	components: {
		Card,
	},
	data: () => ({
		//
	}),
	methods: {
		goNext() {
			this.$refs.mycarousel.goNext();
		},
		goPrev() {
			this.$refs.mycarousel.goPrev();
		},
	},
};
</script>
