<template>
	<div>
		<v-container style="position: relative; z-index: 2">
			<v-row>
				<v-col class="mb-12 text-center" cols="12">
					<div class="mb-9 text-h3 font-weight-bold primary--text">
						features
					</div>

					<div class="pb-9 text-h5 font-weight-semibold">
						On.Command™ interface is optimized for superior user experience
						and management
					</div>
				</v-col>
			</v-row>
		</v-container>

		<SoftwareGallery />
	</div>
</template>

<script>
import SoftwareGallery from '@/components/solutions/software/SoftwareGallery.vue';

export default {
	name: 'Features',
	components: {
		SoftwareGallery,
	},
	data: () => ({
		//
	}),
};
</script>
