<template>
	<div>
		<v-card color="primary" flat tile style="overflow: hidden; padding-top: 20px; padding-bottom: 20px">
			<v-row style="width: 100%; background-color: primary;">
				<v-col class="d-flex justify-center mt-5" cols="12">
					<v-img alt="On.Energy" contain src="@/assets/element__latest.png" height="208" max-width="300"
						transition="fade-transition" width="100%" />
				</v-col>
				<v-col class="d-flex justify-center" cols="12">
					<v-row style="margin: 0; padding: 0;">
						<v-col cols="3"></v-col>
						<v-col cols="6">
							<div class="text-center">
								“our competitive advantage lies in our ability to pair extensive
								project execution experience and proprietary software with a
								demonstrated ability to originate, develop and finance battery
								energy storage systems across various power markets”
							</div>
						</v-col>
						<v-col cols="3"></v-col>
					</v-row>
				</v-col>
				<v-col class="d-flex justify-center my-7" cols="12">
					<div class="text-h5 black--text font-weight-bold">
						alan cooper, CEO
					</div>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
export default {
	name: 'EndToEndQuote',
	data: () => ({
		//
	}),
};
</script>
