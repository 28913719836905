<template>
	<v-container style="padding: 160px 20px">
		<v-row style="position: relative; z-index: 2">
			<v-col class="mb-9" cols="12">
				<div class="section-title text-h3 text-md-h2 font-weight-bold black--text">
					distributed energy, done right
				</div>
			</v-col>

			<v-col v-for="(item, i) in items" :key="i" class="pa-0" cols="12" sm="6" md="4">
				<v-card class="card__net-zero d-flex flex-column align-start pa-9" elevation="0"
					style="height: 100%; padding-bottom: 80px !important">
					<v-card-title v-text="item.title" class="px-0 text-h5 font-weight-semibold black--text" />

					<v-divider />

					<v-card-text v-text="item.text" class="px-0 text-h6 black--text" />

					<v-card-actions class="px-0" style="position: absolute; bottom: 24px">
						<v-btn :to="item.link" aria-label="Learn more about our solutions" class="black--text" text>
							Learn about

							<span v-text="item.title" class="ml-1" />

							<v-icon right> mdi-arrow-right </v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col class="d-flex justify-center my-16" cols="12" md="4">
				<v-img alt="On.Energy" contain src="@/assets/element__round.png" height="300" max-width="300"
					transition="fade-transition" width="100%" />
			</v-col>
		</v-row>
	</v-container>
</template>

<style lang="scss" scoped>
.v-card.card__net-zero {
	background-color: transparent;

	.v-card__actions {
		display: none;
	}

	&:hover {
		background-color: #fff;

		.v-card__actions {
			display: flex;
		}
	}
}
</style>

<script>
export default {
	name: 'EnergySolutions',
	data: () => ({
		items: [
			{
				title: 'end-to-end',
				text: 'this industry is fragmented and projects are complex. our end-to-end business model takes projects from idea, through planning, construction, operation and even financing - all executed by one single & reliable point of responsibility',
				link: '/solutions/end-to-end',
			},
			{
				title: 'software',
				text: 'software is critical to successful projects. we use proprietary technology to develop, design, forecast, control and dispatch our assets - squeezing the most out of every kilowatt deployed',
				link: '/solutions/software',
			},
		],
	}),
};
</script>
