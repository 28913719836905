<template>
	<div>
		<SectionHead title="projects" text="successfully and safely deployed projects for the americas"
			image="head__maverick.png" />

		<Featured />

		<v-card :img="require(`@/assets/head__maverick.png`)"
			class="section__image d-flex align-center justify-center text-center" color="black" dark flat height="300" tile>
			<v-container style="max-width: 800px">
				<v-row>
					<v-col class="d-flex flex-column align-center justify-center" cols="12">
						<div class="mb-6 pt-16 pt-md-0 text-h3 text-md-h2 font-weight-bold primary--text notranslate">
							<v-img alt="On Energy" contain height="192" max-width="100%"
								src="@/assets/logos/logo-animal.png" width="400" />
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-card>


		<v-container style="padding-top: 100px; padding-bottom: 156px">
			<ProjectsUnderConst />
		</v-container>

		<v-container style="padding-bottom: 200px">
			<ProjectsOperational />
		</v-container>

		<v-card color="black" flat tile style="overflow: hidden; padding-top: 160px; padding-bottom: 80px">
			<ContactUs />
		</v-card>
	</div>
</template>

<script>
import SectionHead from '@/components/SectionHead.vue';
import Featured from '@/components/projects/Featured.vue';
import ProjectsUnderConst from '@/components/projects/ProjectsUnderConst.vue';
import ProjectsOperational from '@/components/projects/ProjectsOperational.vue';
import Card from '@/components/projects/Card.vue';
import ContactUs from '@/components/ContactUs.vue';

export default {
	name: 'Projects',
	components: {
		SectionHead,
		Featured,
		ProjectsUnderConst,
		ProjectsOperational,
		Card,
		ContactUs,
	},
};
</script>
