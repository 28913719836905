<template>
    <v-app>
        <Header />

        <v-main class="pt-0">
            <router-view />
        </v-main>

        <Footer />
    </v-app>
</template>

<style lang="scss">
@import "./scss/main.scss";
</style>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
    name: "App",
    components: {
        Header,
        Footer,
    },
    data: () => ({
        //
    }),
};
</script>
